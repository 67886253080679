import React from 'react'

import {
  Button, Form, FormGroup, Label, Input
} from 'reactstrap'

class BookSuggest extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div className="book-suggest">
        <Form className="book-suggest-form" method="POST" action="https://formspree.io/michael.haessig@gmail.com">
          <FormGroup>
            <Input type="text" name="name" id="name" placeholder="Book name*" required/>
          </FormGroup>
          <FormGroup>
            <textarea style={{width:'100%'}} name="message" id="message" rows="5" placeholder="Why this book? ( please include a link )" ></textarea>
          </FormGroup>
          <FormGroup>
            <Input type="email" name="_replyto" id="email" placeholder="E-Mail ( so i can tell you how i liked your suggestion )" />
          </FormGroup>
          <input type="hidden" name="_subject" id="subject" value="New book suggestion" />
          <input type="hidden" name="_next" value="https://www.michaelhaessig.com/books?thanks=1"/>
          <Button color="primary" style={{width:'100%'}}>Submit</Button>
        </Form>
      </div>
    )
  }
}

export default BookSuggest
