import React from 'react'
import { graphql, Link } from 'gatsby'

import BookSuggest from '../components/book-suggest'

import Pluralize from 'react-pluralize'

import HRNumbers from 'human-readable-numbers'

import StarRatingComponent from 'react-star-rating-component'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import Layout from '../components/layout'
import {Button, Col, FormGroup, Input, Row} from 'reactstrap'

class BooksPage extends React.Component {
  constructor(props) {
    super(props)

    // state setup
    // we store only books and stats on the state ( filter changes trigger books updates and full component renders )
    this.state = {
      books: this.props.data.allMarkdownRemark.edges,
      stats: this.calculateBookStats(this.props.data.allMarkdownRemark.edges),
      bookSuggest: false // flag to show hide book suggest form
    }

    // different than in the technology-journey component we store filter in local variable instead of state
    this.filter = {
      tag: '',
      tags: this.uniqueTags(this.props.data.allMarkdownRemark.edges),
      year: '',
      years: this.uniqueYears(this.props.data.allMarkdownRemark.edges),
      search: '',
      rating: '',
      ratings: [1, 2, 3, 4, 5],
    }

    // this binding
    this.filterBooks = this.filterBooks.bind(this)
    this.calculateBookStats = this.calculateBookStats.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.changeSearch = this.changeSearch.bind(this)
    this.changeYear = this.changeYear.bind(this)
    this.changeRating = this.changeRating.bind(this)
    this.filterTag = this.filterTag.bind(this)
    this.filterYear = this.filterYear.bind(this)
    this.filterRating = this.filterRating.bind(this)
    this.filterSearch = this.filterSearch.bind(this)
    this.toggleBookSuggest = this.toggleBookSuggest.bind(this)
  }
  uniqueTags(edges) {
    let tags = []

    edges.forEach(({ node }) => {
      if (node.frontmatter.tags) {
        node.frontmatter.tags.forEach(tag => {
          // add tag if not already
          if (tags.indexOf(tag) == -1) {
            tags.push(tag)
          }
        })
      }
    })

    return tags
  }
  uniqueYears(edges) {
    let years = []

    edges.forEach(({ node }) => {
      if (node.frontmatter.date) {
        let year = parseInt(node.frontmatter.date.substring(0, 4))
        // add year if not already
        if (years.indexOf(year) == -1) {
          years.push(year)
        }
      }
    })

    // sort by year
    years.sort((a, b) => b - a)

    return years
  }
  styleFor(node) {
    return { background: '#000000', color: '#fff' }
  }
  iconFor(node) {
    return <i className="fas fa-book" />
  }
  changeFilter(event) {
    this.filter.tag = event.target.value

    this.filterBooks()
  }
  changeYear(event) {
    this.filter.year = event.target.value

    this.filterBooks()
  }
  changeRating(event) {
    this.filter.rating = event.target.value

    this.filterBooks()
  }
  changeSearch(event) {
    this.filter.search = event.target.value

    this.filterBooks()
  }
  filterBooks() {
    // alway filter from all books
    let books = this.props.data.allMarkdownRemark.edges
      .filter(this.filterTag)
      .filter(this.filterYear)
      .filter(this.filterRating)
      .filter(this.filterSearch)

    let stats = this.calculateBookStats(books)

    // update state and render
    this.setState({
      books: books,
      stats: stats,
    })
  }
  calculateBookStats(books) {
    let stats = {
      bookCount: books.length,
      pageCount: 0,
    }

    books.forEach(({ node }) => {
      if (node.frontmatter.book && node.frontmatter.book.pageCount) {
        stats.pageCount += node.frontmatter.book.pageCount
      }
    })

    return stats
  }
  // {} syntax extracts property from parameter object in its own variable , 'node' in this case
  filterTag({ node }) {
    if (this.filter.tag != '') {
      // filter on the tags
      if (node.frontmatter.tags.indexOf(this.filter.tag) != -1) {
        return true
      }

      return false
    } else {
      // show all
      return true
    }
  }
  filterYear({ node }) {
    if (this.filter.year != '') {
      // filter on the year
      if (node.frontmatter.date.indexOf(this.filter.year) != -1) {
        return true
      }

      return false
    } else {
      // show all
      return true
    }
  }
  filterRating({ node }) {
    if (this.filter.rating != '') {
      // filter on rating
      if (
        node.frontmatter.rating &&
        node.frontmatter.rating == this.filter.rating
      ) {
        return true
      }

      return false
    } else {
      // show all
      return true
    }
  }
  filterSearch({ node }) {
    if (this.filter.search != '') {
      let search = this.filter.search.toLowerCase()

      // search book isbn only equals
      if (node.frontmatter.isbn == search) {
        return true
      }
      // search in book title
      if (node.frontmatter.book.title.toLowerCase().indexOf(search) != -1) {
        return true
      }
      // search book authors
      if (
        node.frontmatter.book.authors &&
        node.frontmatter.book.authors.length > 0
      ) {
        for (let i = 0; i < node.frontmatter.book.authors.length; i++) {
          const author = node.frontmatter.book.authors[i]
          if (author.toLowerCase().indexOf(search) != -1) {
            return true
          }
        }
      }
      // search in markdown content
      if (node.rawMarkdownBody.toLowerCase().indexOf(search) != -1) {
        return true
      }

      return false
    } else {
      return true
    }
  }
  toggleBookSuggest(){
    this.setState({
      bookSuggest: !this.state.bookSuggest,
    });
  }
  render() {
    return (
      <Layout>
        <div className="site-content books">
          <article>
            <div className="post">
              <h1>{this.props.data.markdownRemark.frontmatter.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.data.markdownRemark.html,
                }}
              />
            </div>
          </article>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Search title, author, notes, isbn"
                  value={this.filter.search}
                  onChange={this.changeSearch}
                />
              </FormGroup>
            </Col>
            <Col sm="4" xs="12">
              <FormGroup>
                <Input
                  className="select-filter"
                  type="select"
                  name="rating"
                  value={this.filter.rating}
                  onChange={this.changeRating}
                >
                  <option value="">Choose rating</option>
                  {this.filter.ratings.map(rating => (
                    <option value={rating}>{rating}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col sm="4" xs="12">
              <FormGroup>
                <Input
                  className="select-filter"
                  type="select"
                  name="tags"
                  value={this.filter.tag}
                  onChange={this.changeFilter}
                >
                  <option value="">Choose tag</option>
                  {this.filter.tags.map(tag => (
                    <option value={tag}>{tag}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col sm="4" xs="12">
              <FormGroup>
                <Input
                  className="select-filter"
                  type="select"
                  name="year"
                  value={this.filter.year}
                  onChange={this.changeYear}
                >
                  <option value="">Choose year</option>
                  {this.filter.years.map(year => (
                    <option value={year}>{year}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs="12" className="book-stats-total">
              <span>Total:</span>
              <Pluralize singular={'Book'} count={this.state.stats.bookCount} />
              {HRNumbers.toHumanString(this.state.stats.pageCount)}{' '}
              <Pluralize
                singular={'Page'}
                plural={'Pages'}
                showCount={false}
                count={this.state.stats.pageCount}
              />
              {this.state.bookSuggest ?
                  <Button className="float-right" color="primary" size="sm" onClick={this.toggleBookSuggest}>X</Button>:
                  <Button className="float-right" color="primary" size="sm" onClick={this.toggleBookSuggest}>Suggest a book</Button>
              }
            </Col>
          </Row>
          <hr />
          {this.state.books.length > 0 ? (
            <>
                {this.state.bookSuggest ? (
                  <div className="books-no-result">
                    <BookSuggest/>
                  </div>):
                  <VerticalTimeline layout="1-column">
                {this.state.books.map(({ node }) => (
                  <VerticalTimelineElement
                    key={node.id}
                    className="book-item"
                    iconStyle={this.styleFor(node)}
                    icon={this.iconFor(node)}
                  >
                    <Row>
                      <Col md="3">
                        <a href={node.frontmatter.book.infoLink} target="_blank">
                          <img
                            className="img-fluid"
                            src={node.frontmatter.book.imageLinks.thumbnail}
                          />
                        </a>
                        <div className="book-stats">
                          {node.frontmatter.isbn ? (
                            <span>ISBN: {node.frontmatter.isbn}</span>
                          ) : null}
                          {node.frontmatter.book.authors ? (
                            <span>
                              Author: {node.frontmatter.book.authors.join(',')}
                            </span>
                          ) : null}
                          {node.frontmatter.book.publishedDate ? (
                            <span>
                              Published: {node.frontmatter.book.publishedDate}
                            </span>
                          ) : null}
                          {node.frontmatter.book.pageCount ? (
                            <span>Pages: {node.frontmatter.book.pageCount}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="9">
                        <a href={node.frontmatter.book.infoLink} target="_blank">
                          <h3 className="book-title">
                            {node.frontmatter.book.title}{' '}
                            {node.frontmatter.book.subtitle ? (
                              <span>- {node.frontmatter.book.subtitle}</span>
                            ) : null}{' '}
                          </h3>
                        </a>
                        <div
                          className="book-html"
                          dangerouslySetInnerHTML={{ __html: node.html }}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <time className="entry-date">
                      {node.frontmatter.dateFormat}
                    </time>
                    <div className="float-right">
                      <StarRatingComponent
                        name="rating" /* name of the radio input, it is required */
                        value={
                          node.frontmatter.rating
                        } /* number of selected icon (`0` - none, `1` - first) */
                        starCount={5} /* number of icons in rating, default `5` */
                        editing={
                          false
                        } /* is component available for editing, default `true` */
                      />
                    </div>
                  </VerticalTimelineElement>
                ))}
              </VerticalTimeline>
                }
            </>
          ) : (
            <>
              <div className="books-no-result">
                <i className="fas fa-book-reader" />
                <h3>No books found</h3>
                <h4>Please recommend me your favourite book:</h4>
              </div>
              <BookSuggest/>
            </>
          )}
        </div>
      </Layout>
    )
  }
}

export default BooksPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "booksIntro" } }) {
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "book" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            book {
              title
              subtitle
              authors
              publisher
              publishedDate
              description
              pageCount
              printType
              averageRating
              ratingsCount
              contentVersion
              language
              imageLinks {
                smallThumbnail
                thumbnail
              }
              previewLink
              infoLink
              canonicalVolumeLink
            }
            isbn
            rating
            date
            dateFormat: date(formatString: "D MMMM, YYYY")
            tags
          }
          html
          rawMarkdownBody
        }
      }
    }
  }
`
